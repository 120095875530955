import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL } from "../Const/Url";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import {
  can_change_products,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import ViewIcon from "../CustomCommons/ViewIcon";
import * as path from "../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import { getExportFile } from "../../utils/HelperFunction";
import HelperExportComponent from "../../utils/HelperExportComponent";

const ProductReportFromDashboard = () => {
  const [isLoading, SetisLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPdId, setModalPdId] = useState(null);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const cRef = useRef();
  const [query, setQuery] = useState("");
  const [renking, setRenking] = useState("");
  const [total, setTotal] = useState(0);

  const [adminOrOutlet, setAdminOrOutlet] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [outletList, setOutletList] = useState([]);
  const [homeDecor, setHomedecor] = useState([]);
  const [Instyle, setInstyle] = useState([]);
  const [category, setCategory] = useState(null);

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 10;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const [source, setSource] = useState("");
  const options1 = [
    { value: "", label: "See All" },
    { value: "total_sold", label: "Lowest Sold (Quantity)" },
    { value: "-total_sold", label: "Highest sold (Quantity)" },
    { value: "total_amount", label: "Lowest sold (Amount)" },
    { value: "-total_amount", label: "Highest sold (Amount)" },
    // { value: 2, label: "error" },
  ];
  const options2 = [
    { value: 3, label: "See All" },
    { value: 1, label: "Home Decor" },
    { value: 2, label: "Instyle" },
  ];
  const options3 = [
    { value: "", label: "See All" },
    { value: 1, label: "Admin" },
    { value: 3, label: "Website" },
    { value: 2, label: "Outlet" },
    { value: 4, label: "Admin + Website" },
  ];

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setTotal(res.data?.total);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const getOutlet = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setOutletList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  const getCategory = (setState, id) => {
    const url = `${BASE_URL}api/v1/inventory/inventory/category/?category_type=2&limit=100&main_category=${id}`;

    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setState(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    getOutlet();
    getCategory(setHomedecor, 0);
    getCategory(setInstyle, 1);
  }, []);
  const getSelectOutlet = (val, action) => {
    setSelectedOutlet(val.value);
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/inventory/inventory/invoice-product-report/"}?limit=${limit}&offset=${offset}&search=${query}`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  useEffect(() => {
    // Check conditions to determine when to call the API
    const shouldCallAPI =
      pageNumber > 1 ||
      date1 ||
      query.length > 2 ||
      (date1 && date2) ||
      source ||
      renking ||
      adminOrOutlet;

    if (shouldCallAPI) {
      apiFunction(
        `${BASE_URL}${"api/v1/inventory/inventory/invoice-product-report/"}?limit=${limit}&offset=${offset}&search=${query}${
          source ? `&ordering=${source}` : ""
        }${date1 && !date2 ? `&start=${date1}` : ""}${
          date1 && date2 ? `&start=${date1}&end=${date2}` : ""
        }${adminOrOutlet ? `&source=${adminOrOutlet}` : ""}${
          selectedOutlet && adminOrOutlet === 2
            ? `&outlet=${selectedOutlet}`
            : ""
        }`
      );
    }
  }, [
    pageNumber,
    query,
    source,
    date1,
    date2,
    renking,
    adminOrOutlet,
    selectedOutlet,
  ]);

  // ofset code

  const history = useHistory();
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const [invoiceList, setInvoiceList] = useState([]);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  var options = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });

  useEffect(() => {
    // getInvoiceList();
  }, []);
  useEffect(() => {
    // getInvoiceList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);

  const handleAdminOrOutlet = (newVal, action) => {
    setSelectedOutlet(null);
    setAdminOrOutlet(parseInt(newVal.value));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="mx-3 my-3">
        <div className="cotagorey-head mb-4">
          <div>
            <h3> Product Report </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                setQuery(e.target.value.toLocaleLowerCase());
              }}
            />
          </div>
          <div className="filter_div me-3">
            <div className="d-flex align-items-center">
              <div className="me-2 mb-3 mb-md-0">
                <p className="">Source :</p>
                <div
                  className="sl"
                  style={{ width: "180px", marginTop: "-15px" }}
                >
                  <Select
                    onChange={handleAdminOrOutlet}
                    defaultValue={
                      adminOrOutlet === 2
                        ? [{ value: 2, label: "Outlet" }]
                        : adminOrOutlet === 1
                        ? [{ value: 1, label: "Admin" }]
                        : adminOrOutlet === 3
                        ? [{ value: 3, label: "Website" }]
                        : adminOrOutlet === 4
                        ? [{ value: 4, label: "Admin + Website" }]
                        : [{ value: "", label: "See All" }]
                    }
                    value={
                      adminOrOutlet === 2
                        ? [{ value: 2, label: "Outlet" }]
                        : adminOrOutlet === 1
                        ? [{ value: 1, label: "Admin" }]
                        : adminOrOutlet === 3
                        ? [{ value: 3, label: "Website" }]
                        : adminOrOutlet === 4
                        ? [{ value: 4, label: "Admin + Website" }]
                        : [{ value: "", label: "See All" }]
                    }
                    options={options3}
                    placeholder="---Select---"
                  />
                </div>
              </div>
              {adminOrOutlet === 2 && (
                <div className="me-2 mb-3 mb-md-0">
                  <p className="">Outlets :</p>
                  <div
                    className="sl"
                    style={{ width: "180px", marginTop: "-15px" }}
                  >
                    <Select
                      onChange={getSelectOutlet}
                      options={outletList}
                      placeholder="---Select---"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div ms-2 my-auto me-2  text-white "
              style={{ background: "black", cursor: "pointer" }}
            >
              {/* {items_data?.length !== 0 ? (
                <>
                  <span
                    onClick={() =>
                      getExportFile(
                        `${BASE_URL}${"api/v1/inventory/inventory/top-product-report/"}?export=true&search=${query}${
                          date1 && !date2 ? `&start=${date1}` : ""
                        }${
                          date1 && date2 ? `&start=${date1}&end=${date2}` : ""
                        }${adminOrOutlet ? `&source=${adminOrOutlet}` : ""}${
                          selectedOutlet && adminOrOutlet === 2
                            ? `&outlet=${selectedOutlet}`
                            : ""
                        }`,
                        "product_report_export_file.xlsx"
                      )
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() =>
                      swal({
                        title: "Error!",
                        text: "There Is No Data",
                      })
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              )} */}
              <HelperExportComponent
                url={`${BASE_URL}${"api/v1/inventory/inventory/invoice-product-report/"}?export=true&search=${query}${
                  date1 && !date2 ? `&start=${date1}` : ""
                }${date1 && date2 ? `&start=${date1}&end=${date2}` : ""}${
                  adminOrOutlet ? `&source=${adminOrOutlet}` : ""
                }${
                  selectedOutlet && adminOrOutlet === 2
                    ? `&outlet=${selectedOutlet}`
                    : ""
                }`}
                fileName={"product_report_export_file.xlsx"}
                items_data={items_data}
              />
            </div>
          </div>
        </div>
        <div>
          <h6>Total : {total ? total : 0}</h6>
        </div>
        <div className="my-2">
          <p style={{ color: "red" }}>
            NOTE : The total amount may not reflect the actual sales data, as
            discounts and delivery charges are not included in this product
            report. Please refer to the sales report to view the actual data.
          </p>
        </div>
        {/* categorey list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                    textAlign: "start",
                    paddingLeft: "10px",
                  }}
                >
                  #ID
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Date
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Thumbnail
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  SKU
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Sale
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Stock
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((category, index) => (
                  <tr className="table-row " key={index}>
                    <td>
                      <p className="ms-3 my-auto">{index + 1}</p>
                    </td>
                    <td>{category?.invoice_date}</td>

                    <td>
                      {" "}
                      <img
                        width={50}
                        style={{ margin: "6px 0px !important" }}
                        height={50}
                        src={category.thumb_url}
                        alt=""
                      />
                    </td>
                    <td>{category.name}</td>
                    <td>{category.sku}</td>
                    <td>{category?.quantity_sold}</td>
                    <td>{category?.stock}</td>
                    <td>{category?.amount ? category?.amount : 0}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* entites and paggination */}
        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 10)}
                </span>
              </p>
            </div>
            {/* <div className="col-12 col-md-6 d-flex paggination-button">
            <ReactPaginate
              previousLabel={<PreviousIcon />}
              nextLabel={<NextIcon />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"a"}
              nextLinkClassName={"a"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              initialPage={query?.length > 0 ? 0 : updatedPageNumber}
              forcePage={
                query?.length > 0 || getData?.length < usersPerPage
                  ? 0
                  : updatedPageNumber
              }
            />
          </div> */}
            <div className="col-12 col-md-6 d-flex paggination-button">
              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                    display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                  }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductReportFromDashboard;